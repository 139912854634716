import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="board-export"
export default class extends Controller {
  static targets = ["nextBtn", "submitBtn", "backBtn", "ideas", "fields"];

  connect() {}

  next(ev) {
    ev.preventDefault();

    this.nextBtnTarget.classList.add("d-none");
    this.ideasTarget.classList.add("d-none");

    this.submitBtnTarget.classList.remove("d-none");
    this.backBtnTarget.classList.remove("d-none");
    this.fieldsTarget.classList.remove("d-none");

    document.getElementById("bsModalSize").classList.remove("modal-xl");
  }

  back(ev) {
    ev.preventDefault();

    this.nextBtnTarget.classList.remove("d-none");
    this.ideasTarget.classList.remove("d-none");

    this.submitBtnTarget.classList.add("d-none");
    this.backBtnTarget.classList.add("d-none");
    this.fieldsTarget.classList.add("d-none");

    document.getElementById("bsModalSize").classList.add("modal-xl");
  }
}
